/* Modal_Start --------------------------------------------- */
@use 'variables';
@use 'font';

.sticky-header-container {
  background-color: #FFFFFF;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  padding: 1.5rem 1.5rem 0 1.5rem;
  z-index: 1000;
}

.sticky-body-container {
  overflow-y: auto;
  padding: 0 1.5rem;
}

.sticky-footer-container {
  background: linear-gradient(rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), variables.$white-color, variables.$white-color);
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 1.5rem 1.5rem 1.5rem;
  z-index: 10;
}

.sticky-footer-container.modal-footer-flex-end {
  padding-top: 3rem;
}

.modal-footer {
  border-top: none;
}

.modal-form-negative-padding {
  margin: 0 -1.5rem;
}

.modal-body {
  max-height: calc(100vh - 3rem);
  overflow-y: auto;
  padding: 2rem 1.5rem 1.5rem;
}

.modal-body.sticky {
  padding: 0;
}

.modal-header {
  display: block;
  padding: 0 0 1.25rem 0;
  border-bottom: none;
}

.modal-title {
  display: block;
  color: variables.$primary-text-color;
  @extend .title-md;
}

.modal-subtitle {
  display: block;
  font-size: 1.125rem;
  line-height: 2rem;
  color: variables.$primary-text-color;
  @extend .default-medium;
}

.modal-sub-subtitle {
  font-size: 0.875rem;
  text-align: left;
  color: variables.$primary-text-color;
  @extend .default-medium;
}

.modal-sub-subtitle-text {
  display: inline-block;
  width: 50%;
  color: variables.$extra-dark-gray-color;
  @extend .default-medium;
}

.modal-sub-subtitle-text span {
  display: inline-block;
  color: variables.$primary-text-color;
  @extend .default-semi-bold;
}

.inline-subtitle {
  display: inline-block;
  min-width: 50%;
}

.modal-subtitle-action {
  float: right;
  top: -0.5rem;
  position: relative;
  text-align: right;
}

.footer-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0 0 0;
  float: right;
}

.modal-footer-flex-end {
  display: flex;
  justify-content: flex-end;
}

.modal-divider {
  width: 100%;
  border-bottom: 1px solid variables.$hover-gray-color;
  margin: 0.5rem 0;
  padding-top: 0.5rem;
}


.modal {
  z-index: 1060;
}

.modal-content {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: unset;
  overflow: auto;
}

.csv-help-modal .modal-xl {
  max-width: 920px;
}

.insurance-help-modal .modal-xl {
  max-width: 650px;
}

.auto-grow-modal {
  .modal-dialog {
    @extend .d-flex;
    @extend .justify-content-center;

    .modal-content {
      padding: 2rem;
      width: auto;
      overflow: unset;
    }
  }
}
.modal-body {
  @extend .modal-content;
}
.std-dialog-panel {
  min-width: 28rem;
  overflow: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none
  }
  &-md {
    width: 28rem
  }
}
.std-dialog {
  display: block;
  background-color: variables.$white-color;
  border-radius: variables.$border-radius;
  padding: variables.$px24;
  margin: variables.$px24 0;
  std-header {
    margin-bottom: variables.$px24;
    display: block;
  }
  std-footer {
    margin-top: variables.$px32;
    display: block;
  }
}
