@use "variables";

.std-icon {
  &-xs {
    svg {
      width: variables.$px4;
    }
  }

  &-sm {
    svg {
      width: variables.$px8;
    }
  }

  &-md {
    svg {
      width: variables.$px16;
    }
  }

  &-lg {
    svg {
      width: variables.$px24;
    }
  }

  &-xl {
    svg {
      width: variables.$px48;
    }
  }
}

.std-icon {
  svg {
    height: 100%;
    width: 100%;
  }
}


.icon-size-1 {
  width: variables.$px4;
  svg {
    width: variables.$px4;
  }
}
.icon-size-2 {
  width: variables.$px8;
  svg {
    width: variables.$px8;
  }
}
.icon-size-3 {
  width: variables.$px16;
  height: variables.$px16;
  svg {
    width: variables.$px16;
    height: variables.$px16;
  }
}
.icon-size-4 {
  width: variables.$px24;
  svg {
    width: variables.$px24;
  }
}
.icon-size-5 {
  width: variables.$px48;
  svg {
    width: variables.$px48;
  }
}

.icon-outline {
  path {
    stroke: $black-color;
  }
  fill: none;
  &.white {
    path {
      stroke: $white-color;
    }
  }
}
.icon-solid {
  path {
    fill: $black-color;
  }
  &.white {
    path {
      fill: $white-color;
    }
  }
}
