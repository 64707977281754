.std-btn-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 24px 72px 24px 0;
  gap: 16px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
