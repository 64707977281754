// If you want to override variables do it here
@import "variables";
@import "font";

// Import styles with default layout.
@import '@coreui/coreui-pro/scss/coreui';

// Spinkit
@import 'spinkit/scss/spinkit.scss';


// Import toast style
@import 'ngx-toastr/toastr';

// If you want to add something do it here - must be below all other imported styles
@import "custom";
@import "stylesheet";
@import "material";
@import "cursor";
@import "print";
@import "images";
@import "icons";
@import "buttons";
@import "tabs";
@import "modals";
@import "alerts";
@import "spacer";
footer.std-footer {
  &:before {
    content: '';
    height: 1px;
    background-color: $lighter-grey-color;
    width: 100%;
    display: block;
    clear: both;
  }
  width: 100%;
  bottom: 0;
  background-color: $footer-color;
}


.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
app-default-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-body .main {
  flex: 1;
  min-width: 0;
  min-height: 100vh;
}

body {
  overflow-y: auto;
  -ms-overflow-style: none;
  overflow-x: hidden;
  background-color: $background-color;
}

hr {
  //background-color: $default-grey-color;
  border-top: 1px solid $default-grey-color;
  margin: 0;
}

.main-content {
  padding: $app-padding;
}
.no-padding {
  padding: 0;
}
.no-margin {
  margin: 0;
}
.no-margin-imp {
  margin: 0!important;
}

.auto-margin {
  margin: auto;
}

// padding and margins
.no-padding {
  padding: 0;
}
.p-8px {
  padding: $px8;
}
.p-16px {
  padding: $px16;
}
.p-24px {
  padding: $px24;
}
.p-64px {
  padding: $px64;
}

.pb-8px {
  padding-bottom: 0.5rem;
}
.pb-24px {
  padding-bottom: 1.5rem;
}

.mt-none {
  margin-top: 0.0rem;
}

.mt-8px {
  margin-top: 0.5rem;
}

.mt-10px {
  margin-top: 0.625rem;
}

.mt-14px {
  margin-top: 0.875rem;
}

.mt-16px {
  margin-top: 1rem;
}

.mt-20px {
  margin-top: 1.25rem;
}

.mt-24px {
  margin-top: 1.5rem;
}

.mt-28px {
  margin-top: 1.75rem;
}

.mt-48px {
  margin-top: 3rem;
}

.mb-none {
  margin-bottom: 0;
}

.mb-16px {
  margin-bottom: 1rem;
}

.mb-20px {
  margin-bottom: 1.25rem;
}

.mb-24px {
  margin-bottom: 1.5rem;
}

.mb-32px {
  margin-bottom: 2rem;
}

.lh-80p {
  line-height: 80%;
}

// Flexing
.flex-container {
  display: flex;
}

.flex-inline {
  display: inline-flex;
}

.flex-wrap-content {
  flex-wrap: wrap;
}

.flex-content-end {
  justify-content: flex-end;
}

.flex-align-end {
  align-items: flex-end;
}

// Align
.align-self-center {
  align-self: center;
}

// Float
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.no-padding {
  padding: 0;
}

.pt-8px {
  padding-top: 0.5rem;
}

.pt-16px {
  padding-top: 1rem;
}

.pt-24px {
  padding-top: 1.5rem;
}

.pb-8px {
  padding-bottom: 0.5rem;
}

.mt-n16px {
  margin-top: -1rem;
}

.mt-none {
  margin-top: 0.0rem;
}

.mt-2px {
  margin-top: 0.125rem;
}

.mt-4px {
  margin-bottom: 0.25rem;
}

.mt-6px {
  margin-top: 0.375rem;
}

.mt-8px {
  margin-top: 0.5rem;
}

.mt-10px {
  margin-top: 0.625rem;
}

.mt-12px {
  margin-top: 0.75rem;
}

.mt-14px {
  margin-top: 0.875rem;
}

.mt-16px {
  margin-top: 1rem;
}

.mt-20px {
  margin-top: 1.25rem;
}

.mt-24px {
  margin-top: 1.5rem;
}

.mt-28px {
  margin-top: 1.75rem;
}

.mt-32px {
  margin-top: 2rem;
}

.mt-40px {
  margin-top: 2.5rem;
}

.mt-48px {
  margin-top: 3rem;
}

.ml-8px {
  margin-left: 0.5rem;
}


.ml-10px {
  margin-left: 0.625rem;
}

.ml-12px {
  margin-left: 0.75rem;
}

.ml-16px {
  margin-left: 1rem;
}
.ml-24px {
  margin-left: 1.5rem;
}

.ml-16px-force {
  margin-left: 1rem !important;
}

.mr-6px {
  margin-right: 0.375rem;
}

.mr-10px {
  margin-right: 0.625rem;
}

.mr-24px {
  margin-right: 1.5rem;
}

.mb-4px {
  margin-top: 0.25rem;
}
.mb-12px {
  margin-bottom: $px12;
}
.mb-16px {
  margin-bottom: 1rem;
}

.mb-20px {
  margin-bottom: 1.25rem;
}

.mb-24px {
  margin-bottom: 1.5rem;
}

.mb-32px {
  margin-bottom: 2rem;
}

.mb-64px {
  margin-bottom: 4rem;
}

.mb-96px {
  margin-bottom: 6rem;
}

.gap-4px{
  gap: 0.25rem;
}

.gap-6px{
  gap: 0.375rem;
}

.gap-8px{
  gap: 0.5rem;
}

.gap-10px{
  gap: 0.625rem;
}

.gap-12px{
  gap: 0.75rem;
}

.gap-16px{
  gap: 1rem;
}

.gap-20px{
  gap: 1.25rem;
}

.gap-24px{
  gap: 1.5rem;
}

.gap-32px{
  gap: 2rem;
}

.gap-64px{
  gap: 4rem;
}

.gap-96px{
  gap: 6rem;
}

.gap-column-4px{
  column-gap: 0.25rem;
}

.gap-column-6px{
  column-gap: 0.375rem;
}

.gap-column-8px{
  column-gap: 0.5rem;
}

.gap-column-10px{
  column-gap: 0.625rem;
}

.gap-column-12px{
  column-gap: 0.75rem;
}

.gap-column-16px{
  column-gap: 1rem;
}

.gap-column-20px{
  column-gap: 1.25rem;
}

.gap-column-24px{
  column-gap: 1.5rem;
}

.gap-column-32px{
  column-gap: 2rem;
}

.gap-column-64px{
  column-gap: 4rem;
}

.gap-column-96px{
  column-gap: 6rem;
}

.gap-row-4px{
  row-gap: 0.25rem;
}

.gap-row-6px{
  row-gap: 0.375rem;
}

.gap-row-8px{
  row-gap: 0.5rem;
}

.gap-row-10px{
  row-gap: 0.625rem;
}

.gap-row-12px{
  row-gap: 0.75rem;
}

.gap-row-16px{
  row-gap: 1rem;
}

.gap-row-20px{
  row-gap: 1.25rem;
}

.gap-row-24px{
  row-gap: 1.5rem;
}

.gap-row-32px{
  row-gap: 2rem;
}

.gap-row-64px{
  row-gap: 4rem;
}

.gap-row-96px{
  row-gap: 6rem;
}

.lh-80p {
  line-height: 80%;
}

// Flexing
.flex-container {
  display: flex;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-inline {
  display: inline-flex;
}

.flex-wrap-content {
  flex-wrap: wrap;
}

.flex-content-end {
  justify-content: flex-end;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-justify-center {
  justify-content: center
}

.flex-align-center {
  align-items: center
}

.flex-fill {
  flex: 1;
}

.full-centered-text {
  text-align: center;
  width: 100%;
}

// Align
.align-self-flex-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

// Float
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-down {
  float: bottom
}

.float-up {
  float: top;
}

// Sizing
.h100p {
  height: 100%;
}

.w100p {
  width: 100%
}

.w50p {
  width: 50%
}

// Block
.inline-block {
  display: inline-block;
}

.block {
  display: block;
}


.blurred-background {
  backdrop-filter: blur(2px);
}

// Toastr

.toast-container .custom-toast.ngx-toastr {
  display: flex;
  flex-direction: column;
  padding-right: 2rem;
  box-shadow: 0 0 0.25rem #00000029;
  border-radius: 10px;
  .toast-close-button {
    position: absolute;
    right: 1rem;
    top: 0;
    bottom: 0;
  }

  .toast-title {
    @extend .label-md
  }

  .toast-message {
    @extend .label-md
  }
}

.toast-container .custom-toast.ngx-toastr.toast-success {
  background-color: $toastr-success-color;
}

.toast-container .custom-toast.ngx-toastr.toast-info {
  background-color: $toastr-info-color;
}

.toast-container .custom-toast.ngx-toastr.toast-warning {
  background-color: $toastr-warning-color;
}

.toast-container .custom-toast.ngx-toastr.toast-error {
  background-color: $toastr-error-color;
}

// Text wrapping

.ellipsis-nowrap-text {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.live-view-modal-landscape .modal-xl {
  max-width: 95vw;
  max-height: 90vh;
}

.live-view-modal-portrait .modal-xl {
  max-width: 65vw;
  max-height: 95vh;
}

// tooltip styling needs to be in the global stylesheet
.tooltip {
  font-size: 0.9rem;
  z-index: 1052 !important;
}

.show.tooltip {
  opacity: 1;
  z-index: 1052 !important;
}

.cdk-overlay-container {
  z-index: 1052;
}

.tooltip.tooltip-top,
.tooltip.tooltip-bottom,
.tooltip.tooltip-left,
.tooltip.tooltip-right {
  z-index: 100000;
}

// Animating

.animating {
  animation: 0.75s flicker-white-color infinite alternate;
}

.dark-animation.animating {
  animation: 0.75s flicker-dark-color infinite alternate;
}

@keyframes flicker-white-color {
  0% {
    background-color: rgba(255, 255, 255, 0.1);
  }
  10% {
    background-color: rgba(255, 255, 255, 0.2);
  }
  20% {
    background-color: rgba(255, 255, 255, 0.3);
  }
  30% {
    background-color: rgba(255, 255, 255, 0.4);
  }
  40% {
    background-color: rgba(255, 255, 255, 0.5);
  }
  50% {
    background-color: rgba(255, 255, 255, 0.6);
  }
  60% {
    background-color: rgba(255, 255, 255, 0.5);
  }
  70% {
    background-color: rgba(255, 255, 255, 0.4);
  }
  80% {
    background-color: rgba(255, 255, 255, 0.3);
  }
  90% {
    background-color: rgba(255, 255, 255, 0.2);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

@keyframes flicker-dark-color {
  0% {
    background-color: rgba(200, 200, 200, 0.1);
  }
  10% {
    background-color: rgba(200, 200, 200, 0.2);
  }
  20% {
    background-color: rgba(200, 200, 200, 0.3);
  }
  30% {
    background-color: rgba(200, 200, 200, 0.4);
  }
  40% {
    background-color: rgba(200, 200, 200, 0.5);
  }
  50% {
    background-color: rgba(200, 200, 200, 0.6);
  }
  60% {
    background-color: rgba(200, 200, 200, 0.5);
  }
  70% {
    background-color: rgba(200, 200, 200, 0.4);
  }
  80% {
    background-color: rgba(200, 200, 200, 0.3);
  }
  90% {
    background-color: rgba(200, 200, 200, 0.2);
  }
  100% {
    background-color: rgba(200, 200, 200, 0.1);
  }
}

// images
.photo-border-radius {
  border-radius: 0.5rem;
}
.photo-xl {
  max-width: 47rem;
  max-height: 47rem;
}



// copy pasta
.delivery-photo-modal {
  @extend .photo-border-radius;
  @extend .auto-margin;
  @extend .photo-xl;
}




.section-subtitle {
  @extend .label-sm;
  color: $mid-grey-color;
}
.notes {
  @extend .paragraph-md;
  @extend .f14px;
}
.photo-gallery {
  gap: 1.25rem;
}
.delivery-photo-border {
  border-radius: 0.5rem;
}
.delivery-photo {
  @extend .delivery-photo-border;
  width: 7rem;
  height: 7rem;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  cursor: pointer;
}

.photo-modal {
  gap: 2rem;
  min-height: 20rem;
  min-width: 20rem;
  .photo-title {
    @extend .title-md;
  }
  .delivery-photo-modal {
    max-width: 47rem;
    max-height: 47rem;
    @extend .auto-margin;
    @extend .delivery-photo-border;
  }
}

.signature {
  @extend .delivery-photo-border;
  width: auto;
  max-height: 7rem;
  cursor: pointer;
}

.timeline {
  display: flex;
  flex-direction: column;

  std-timeline-item {
    display: flex;
    justify-content: flex-start;

    .icon {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .content {
      margin-bottom: 1.5rem;
      margin-left: 1.5rem;
      width: 100%;
    }
  }

  std-timeline-item .icon:after {
    position: relative;
    content: "";
    border-left: 1px solid $faint-grey;
    border-right: 1px solid $faint-grey;
    background-color: $faint-grey;
    margin: 0.5rem 0;
    height: 100%;
    border-radius: 10px;
  }

  *:last-child > std-timeline-item:last-of-type,
  > std-timeline-item:last-child {
    .content {
      margin-bottom: unset;
    }
    .icon:after {
      content: unset;
    }
  }
  *:only-child > std-timeline-item:only-of-type,
  > std-timeline-item:only-child {
    .icon:after {
      content: unset;
    }
  }
}

.no-notes{
  @extend .paragraph-md;
  @extend .f14px;
}
.notes-title {
  @extend .title-xs;
}
.note-log {
  @extend .d-flex;
  @extend .flex-column;
  @extend .mt-3;

  .note-list-item {
    position: relative;
    list-style: none;

    .note {
      @extend .d-flex;
      @extend .justify-content-start;
      display: inline;


      .icon {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          @extend .rounded-circle;
          @extend .p-2;
          background-color: $primary-blue-color;
        }
      }

      .info {
        @extend .ml-4;
        @extend .mb-24px;

        .date {
          @extend .paragraph-sm;
        }

        .text {
          @extend .label-md;
          @extend .mt-4px;
          @extend .mb-4px;
        }

        .author {
          @extend .label-sm;
          color: $mid-grey-color;
        }
      }
    }
  }

  .note-list-item .icon:after {
    position: relative;
    content: "";
    border-left: 1px solid $faint-grey;
    border-right: 1px solid $faint-grey;
    background-color: $faint-grey;
    margin: 0.5rem 0;
    height: 100%;
    border-radius: 10px;
  }
  .note-list-item:last-child .icon:after,
  .note-list-item:only-child .icon:after {
    content: unset;
  }
  .note-list-item:last-child .info {
    margin-bottom: unset !important;
  }
}
.loading-div-size-default {
  height: 20rem;
  width: 20rem;
}
.label-with-value {

  .small {
    .label {
      @extend .title-xs;
    }
    .value {
      @extend .dm-sans-medium;
      @extend .f14px;
      line-height: normal;
    }
  }

  .large {
    .label {
      @extend .title-md;
    }
    .value {
      @extend .label-lg;
    }
  }

  .clickable {
    cursor: pointer;
    color: $primary-blue-color;
  }
}

input.no-arrows::-webkit-outer-spin-button,
input.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.no-arrows[type=number] {
  -moz-appearance: textfield;
}

.driver-hours-input {
  width: 2.5rem;
  margin-bottom: unset;
  text-align: center;
}
a.std-link {
  color: $primary-color;
}

.min100vh {
  min-height: 100vh;
}

div#hubspot-messages-iframe-container.d-none {
  display: none !important;
}

//Report screen dropdown control CSS

.report-mat-form-field .mat-form-field-flex {
  padding: 0px!important;
}

.report-mat-form-field {
  width: 100%!important;
  border: 1px solid #e4e7ea;
  border-radius: 0.625rem;
  overflow: hidden;
  height: 2.5rem;
}

.report-mat-form-field .mat-form-field-appearance-fill .mat-form-field-underline::before {
  height: 0 !important;
}

.report-mat-form-field .mat-form-field-underline {
  width: 0 !important;
}

.report-mat-form-field .report-input {
  padding-left: 0.5rem;
  height: 2.5rem;
}
.report-lbl .label{
  padding-top: 5px;
}

// .report-mat-form-field .mat-form-field-appearance-fill .mat-form-field-infix {
//   padding:0;
// }

.report-mat-form-field .mat-form-field-infix {
  width: auto;
  padding:0;
  border-top:0;
}

.report-mat-form-field  .mat-form-field-wrapper {
  padding: 0;
}

.search-results-container {
  overflow-y: auto;
  max-height: 300px;
  top: 57px;
}

.mat-custom .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.mat-custom .mat-form-field-underline {
  width: 0 !important;
}

.mat-custom .mat-form-field-label-wrapper {
  position: absolute;
  left: -10px;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  font-size: 0.75rem !important;
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  top: -36px;
}
.mat-custom .mat-form-field-label-wrapper:not(:has(.mat-empty)) { 
  font-size: 16px !important; 
  top: -28px !important;
}

.mat-custom .mat-form-field-label {
   color: black!important
}
.mat-custom .mat-form-field.mat-focused .mat-form-field-label {
  color: black!important;
  font-size: 16px!important;
}

.mat-custom .mat-form-field-flex {
  padding: 0 0.75em 0 .75em !important;
  align-items: center !important;
  height: 2.5rem !important;
  border-radius: 0.625rem !important;
 }

 
.mat-custom .mat-form-field-appearance-fill .mat-form-field-underline::before {
  height: 0 !important;
}

.mat-custom {
  border: 1px solid #e4e7ea;
  border-radius: 0.625rem;
  width: 100%;
  margin-top: 20px;
  height: 2.5rem !important;
}

.mat-custom .mat-select-arrow {
  margin-top: 10px !important;
}


