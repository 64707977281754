.print-button {
  position: fixed !important;
  top: 2.5rem;
  right: 2.5rem;
  z-index: 9999;
  border-radius: 25px;
  .icon {
    width: 1.25rem;
  }
}

@media print {
  .no-print {
    display: none;
  }
  iframe {
    display: none;
  }
  app-printer-toolbar {
    display: none;
  }
  .print-button {
    display: none;
  }
  div#hubspot-messages-iframe-container {
    display: none !important;
  }
}
