@use 'variables';
// labels
.label {
  line-height: normal;
  &.hint {
    @extend .dm-sans-medium;
    @extend .f10px;
  }

  &.xs {
    @extend .dm-sans-medium;
    @extend .f10px;
  }

  &.sm {
    @extend .dm-sans-medium;
    @extend .f12px;
  }

  &.md {
    @extend .dm-sans-medium;
    @extend .f14px;
  }

  &.lg {
    @extend .dm-sans-medium;
    @extend .f16px;
  }
  &.xl {
    @extend .dm-sans-medium;
    @extend .f20px;
  }
  &.xxl {
    @extend .dm-sans-medium;
    @extend .f24px;
  }
  &.sub-label {
    color: variables.$mid-grey-color;
  }
}
.label-hint {
  @extend .dm-sans-medium;
  @extend .f10px;
  line-height: normal;
}

.label-xs {
  @extend .dm-sans-medium;
  @extend .f10px;
  line-height: normal;
}

.label-sm {
  @extend .dm-sans-medium;
  @extend .f12px;
  line-height: normal;
}

.label-md {
  @extend .dm-sans-medium;
  @extend .f14px;
  line-height: normal;
}

.label-lg {
  @extend .dm-sans-medium;
  @extend .f16px;
  line-height: normal;
}

.label-sub-sm {
  @extend .label-sm;
  color: variables.$mid-grey-color;
}

// titles

.title-xxs {
  @extend .dm-sans-bold;
  @extend .f14px;
  line-height: normal;
  letter-spacing: -0.02em;
}

.title {
  @extend .dm-sans-bold;
  line-height: normal;
  letter-spacing: -0.02em;
  &.mini {
    @extend .f16px;
  }
  &.xs {
    @extend .f16px;
  }
  &.sm {
    @extend .f20px;
  }
  &.md {
    @extend .f24px;
  }
  &.lg {
    @extend .f32px;
  }
}
.title-xs {
  @extend .dm-sans-bold;
  @extend .f16px;
  line-height: normal;
  letter-spacing: -0.02em;
}

.title-sm {
  @extend .dm-sans-bold;
  @extend .f20px;
  line-height: normal;
  letter-spacing: -0.02em;
}

.title-md {
  @extend .dm-sans-bold;
  @extend .f24px;
  line-height: normal;
  letter-spacing: -0.02em;
}

.title-lg {
  @extend .dm-sans-bold;
  @extend .f32px;
  line-height: normal;
  letter-spacing: -0.02em;
}

// Paragraphs
.paragraph {
  line-height: 120%;
  @extend .dm-sans-regular;
  &.tiny {
    @extend .f8px;
  }
  &.xs {
    @extend .f10px;
  }
  &.sm {
    @extend .f12px;
  }
  &.md {
    @extend .f14px;
  }
  &.lg {
    @extend .f16px;
  }
}
.paragraph-tiny {
  @extend .dm-sans-regular;
  @extend .f8px;
  line-height: 120%;
}
.paragraph-sm {
  @extend .dm-sans-regular;
  @extend .f10px;
  line-height: normal;
}

.paragraph-md {
  @extend .dm-sans-regular;
  @extend .f12px;
  line-height: normal;
}
.paragraph-md-14px {
  @extend .dm-sans-regular;
  @extend .f14px;
  line-height: 120%;
}

// Default Font

.default-italic {
  @extend .dm-sans-italic;
}

.default-regular {
  @extend .dm-sans-regular;
}

.default-medium {
  @extend .dm-sans-medium;
}

.default-bold-italic {
  @extend .dm-sans-bold-italic;
}

.default-semi-bold {
  @extend .dm-sans-semi-bold;
}

.default-semi-bold-italic {
  @extend .dm-sans-bold-italic;
}

.default-bold {
  @extend .dm-sans-bold;
}

// DM Sans Font

.dm-sans-italic {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-style: italic;
}

.dm-sans-regular {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.dm-sans-medium {
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.dm-sans-semi-bold {
  font-family: 'DM Sans', sans-serif;
  font-weight: 600;
  font-style: normal;
}

.dm-sans-semi-bold-italic {
  font-family: 'DM Sans', sans-serif;
  font-weight: 600;
  font-style: italic;
}

.dm-sans-bold-italic {
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-style: italic;
}

.dm-sans-bold {
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-regular {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: normal;
}

// sizes
.f8px {font-size: 0.5rem;}
.f10px {font-size: 0.625rem;}
.f12px {font-size: 0.75rem;}
.f14px {font-size: 0.875rem;}
.f16px {font-size: 1rem;}
.f18px {font-size: 1.125rem;}
.f20px {font-size: 1.25rem;}
.f24px {font-size: 1.5rem;}
.f32px {font-size: 2rem;}
.f48px {font-size: 3rem;}
