@import "variables";
.nav-logo {
  position: absolute;
  margin: 2rem 1rem;
  cursor: pointer;
}

.nav-client-logo {
  position: absolute;
  margin: 2rem 1rem;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  overflow: hidden;
}

.nav-client-logo.no-logo {
  background-color: $faint-grey;
}
