/* Alert_Start ----------------------------------------- */

.custom-alert {
  @extend .label-md;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $mid-grey-color;
  color: $light-text-color;
}

.custom-alert-icon {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
}
.custom-alert.error,
.custom-alert.custom-alert-danger {
  background-color: $red-color;
  color: $light-text-color;
}

.custom-alert.success,
.custom-alert.custom-alert-success {
  background-color: $green-color;
  color: $light-text-color;
}

.custom-alert.warning,
.custom-alert.custom-alert-warning {
  background-color: $yellow-color;
  color: $light-text-color;
}
.custom-alert.primary,
.custom-alert.custom-alert-primary {
  background-color: $primary-color;
  color: $light-text-color;
}

.warning-message {
  margin: 0.5rem 1.5rem;
  padding: 0.75rem 1rem;
  background-color: $yellow-color-80;
  border-radius: 0.5rem;
  text-align: left;
  color: $primary-text-color;
  @extend .default-semi-bold;
}

.warning-message .tooltip-container {
  float: right;
}

/* ------------------------------------------- Alert_End */
