@use 'variables';
@import "font";

.tab-item-content {
  height: 100%;
}

.tab-icon {
  margin-right: 0.625rem;
}

.tacustom-style {
  @extend .tacustom-background;
}

.tacustom-background {
  background-color: $secondary-color;
}

 .mat-tab-header {
  border-bottom: none !important;
}

 .mat-tab-list {
  border-bottom: 2px solid $off-white-color;
}

 .mat-ink-bar {
  background-color: $black-color !important;
  bottom: auto !important;
}

 .mat-tab-link {
  //padding: 0.625rem 1.25rem;
  padding: 0 22px!important;
  justify-content: left;
  min-width: 0;
  opacity: 1;
  color: $mid-grey-color;
  @extend .label-md;
}

 .mat-tab-label-active {
  color: $primary-text-color;
}

 .mat-tab-body-wrapper {
  flex: 1;
}
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: unset;
}
.login-tab-container,
.std-stepper {
  background: white;
  border-radius: variables.$border-radius;
  overflow: hidden;
  .mat-tab-link {
    width: -moz-available;
    width: -webkit-fill-available;
    display: flex;
    justify-content: center;
  }
  .tab-container,
  .std-stepper-body {
    margin: variables.$px24;
  }
}
.mat-tab-nav-bar {
  .mat-tab-link {
    min-width: unset;
    &:hover {
      background-color: variables.$lighter-grey-color;
      color: variables.$dark-grey-color;
    }
  }
}

.signup-driver-steps {
  .mat-tab-label {    
    min-width: 25%;
  }
  .mat-tab-body-wrapper{ 
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
