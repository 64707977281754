// Global Constants
$sidebar-width: 276px;

// pixels to rem
$px2: 0.125rem;
$px4: 0.25rem;
$px8: 0.5rem;
$px10: 0.625rem;
$px12: 0.75rem;
$px14: 0.875rem;
$px16: 1rem;
$px18: 1.125rem;
$px20: 1.25rem;
$px24: 1.5rem;
$px28: 1.75rem;
$px32: 2rem;
$px36: 2.25rem;
$px40: 2.5rem;
$px48: 3rem;
$px64: 4rem;
$px72: 4.5rem;
$px88: 5.5rem;
$px96: 6rem;
$px128: 8rem;
$px192: 12rem;

// disabled the bootstrap print styles, there's something in
$enable-print-styles: false;

// Default Colors
$background-color: #FFFFFF;
$primary-blue-color: #1A56DB;
$hover-blue-color: #385271;
$light-blue-color: #4d5e72;
$dark-blue-color: #17293E;
$white-color: #FFFFFF;
$off-white-color: #F7F7F7;
$extra-light-grey-color: #e8e8e8;
$default-grey-color: #F2F2F2;
$hover-gray-color: #DADBDE;
$light-grey-color: #CFCFCF;
$lightest-grey-color: #FCFCFC;
$lighter-grey-color: #EAEAEA;
$mid-grey-color: #898989;
$dark-grey-color: #4F4F4F;
$extra-dark-gray-color: #737680;
$light-red-color: #EE4F4C33;
$red-color: #EE4F4C;
$off-red-color: #984545;
$black-color: #111111;
$faint-grey: #EEEEEE;
$turquoise-color: #B1E7E2;
$ocean-color: #00504B;
$salmon-color: #F28872;
$active-color: #31bf5d;
$inactive-color: #737680;
$green-color: #63D58F;
$yellow-color: #F9C731;
$yellow-color-80: #FFD74BCC;

$primary-color: $primary-blue-color;
$secondary-color: $white-color;
$primary-text-color: $black-color;
$grey-text-color: $mid-grey-color;
$light-text-color: $white-color;

$error-color: $red-color;
$danger-color: $red-color;
$warning-color: $yellow-color;
$succes-color: #048B4D;

// Nav Items
$nav-background-color: $off-white-color;
$nav-hover-color: $faint-grey;
$nav-active-text-color: $black-color;
$nav-active-background-color: $faint-grey;
$nav-inactive-text-color: $black-color;
$nav-notification-pill-color: $red-color;
$footer-color: $lightest-grey-color;


// Button Items
$button-white-text-color: $white-color;
$button-black-text-color: $black-color;
$button-preferred-hover-color: $hover-blue-color;
$button-neutral-color: $default-grey-color;
$button-neutral-hover-color: $hover-gray-color;
$button-dark-neutral-color: $mid-grey-color;
$button-dark-neutral-hover-color: $extra-dark-gray-color;
$button-outlined-hover-color: $default-grey-color;
$button-border-color: $hover-gray-color;
$button-text-color: $white-color;
$button-text-hover-color: $off-white-color;
//$button-border-radius: .625rem;

// Label Items
$label-positive-color: $turquoise-color;
$label-positive-text-color: $ocean-color;
$label-neutral-color: $hover-gray-color;
$label-neutral-text-color: $black-color;
$label-negative-color: $salmon-color;
$label-negative-text-color: $white-color;
$label-placeholder-text-color: $mid-grey-color;

// Tabs
$material: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: $primary-color,
  600: #1e88e5,
  700: #1976d2,
  800: #1565c0,
  900: #0d47a1,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: $primary-text-color,
    100: $primary-text-color,
    200: $primary-text-color,
    300: $primary-text-color,
    400: $primary-text-color,
    500: $light-text-color,
    600: $light-text-color,
    700: $light-text-color,
    800: $light-text-color,
    900: $light-text-color,
    A100: $primary-text-color,
    A200: $light-text-color,
    A400: $light-text-color,
    A700: $light-text-color,
  )
);

$theme-colors: (
        "primary": $primary-color,
        "danger": $danger-color,
        "warning": $warning-color,
        "success": $succes-color,
        "secondary": $button-neutral-color,
);

$app-padding: 2.5rem;

// bootstrap/coreui
$std-input-box-shadow: none;
$border-radius: 0.625rem;
$input-focus-border-color: $black-color;
$input-focus-box-shadow: $std-input-box-shadow;
$custom-select-focus-box-shadow: $std-input-box-shadow;
$input-color: $primary-text-color;
$input-bg: $default-grey-color;
$input-suffix-bg: $input-bg;
$input-prefix-bg: $input-bg;
$btn-padding-x: 1rem;
$btn-padding-y: .75rem;
$btn-transition: background 0.8s;

$input-border-color: #e4e7ea;
$input-border-width: 1px;
$input-border: $input-border-width solid $input-border-color;
$input-height: 2.5rem;
$input-font-size: 0.875rem;
$input-disabled-font-color: $mid-grey-color;
$select-disabled-font-color: $input-disabled-font-color;
$custom-select-indicator: url('/assets/icons/dark/solid/chevron-down.svg');
$custom-select-bg-size: 1.5rem;

$input-icon-height: $px40;
$input-icon-width: $input-icon-height;
$input-icon-margin: $px10;

$form-check-input-gutter: 0;

// std forms
$form-field-gap: .125rem;
$form-field-footer-left-right-padding: .5rem;
$form-field-margin-bottom: $px24;

$overlay-background-color: #E5E5E5;

// Drop Down
$dropdown-base-color: $default-grey-color;
$dropdown-hover-color: $default-grey-color;
$dropdown-text-color: $black-color;
$dropdown-border-color: $black-color;
$dropdown-hover-border-color: $black-color;
$dropdown-hover-text-color: $black-color;

// Pills
$pill-text-color: $black-color;
$pill-text-active-color: $white-color;
$pill-default-color: $default-grey-color;
$pill-hover-color: $hover-gray-color;
$pill-active-color: $primary-blue-color;

$pill-success-text: $succes-color;
$pill-success-border: $pill-success-text;
$pill-success-background: #64FFB7;

$pill-warning-text: #B58C10;
$pill-warning-border: $pill-warning-text;
$pill-warning-background: #FFE38E;

$pill-primary-text: #1A56DB;
$pill-primary-border: $pill-primary-text;
$pill-primary-background: #BBD0FF;

$pill-review-text: #3496DD;
$pill-review-border: #3496DD;
$pill-review-background: rgba(52, 150, 221, 0.2);

$pill-danger-text: #AA2926;
$pill-danger-border: $pill-danger-text;
$pill-danger-background: #FFA19F;

$pill-base-text: $mid-grey-color;
$pill-base-border: $pill-base-text;
$pill-base-background: $faint-grey;

$pill-md-padding: $px12 $px16;
$pill-sm-padding: $px4 $px8;

// Checkboxes
$checkbox-base-color: $faint-grey;
$checkbox-hover-color: $hover-gray-color;
$checkbox-checkmark-color: $white-color;
$checkbox-checked-color: $primary-color;
$checkbox-error-color: $error-color;

// Switches
$switch-base-color: $extra-light-grey-color;
$switch-hover-color: $hover-gray-color;
$switch-activated-color: $primary-color;

// Radio
$radio-base-color: $default-grey-color;
$radio-hover-color: $hover-gray-color;
$radio-activated-color: $primary-color;

// Toggle
$toggle-base-color: $default-grey-color;
$toggle-hover-color: $hover-gray-color;
$toggle-activated-color: $primary-color;

// Media Upload
$media-upload-border: $hover-gray-color;
$media-upload-background: $off-white-color;
$media-upload-blue-color: #388EFC;
$media-upload-grey-color: $extra-dark-gray-color;
$media-name-color: #A2A2A2;
$media-size-color: #A2A2A2;
$media-error-color: $red-color;

// Lists
$list-background-color: #FDFDFD;
$list-border-color: #707070;
$list-hover-color: $off-white-color;
$list-active-color: #DEECFF;

// Cards
$card-base-color: #9FA2AD;
$card-banner-color: $default-grey-color;
$card-hover-color: $hover-gray-color;
$card-title-color: $extra-dark-gray-color;
$card-text-color: $black-color;

// Toastr
$toastr-success-color: $green-color;
$toastr-info-color: $primary-color;
$toastr-warning-color: $yellow-color;
$toastr-error-color: $salmon-color;

// Loading
$progress-bar-background-color: $off-white-color;
$progress-bar-progress-color: $primary-blue-color;
$progress-bar-error-color: $red-color;
$progress-bar-success-color: $green-color;

// Textfield
$textfield-fill-color: $default-grey-color;
$textfield-focus-border-color: $black-color;
$textfield-focus-fill-color: $textfield-fill-color;
$textfield-error-red-color: $red-color;

// Dropdown
$dropdown-fill-color: $default-grey-color;


// Datatable
$datatable-row-even-background: $off-white-color;
$datatable-row-odd-background: $white-color;
$datatable-hover-background: $faint-grey;
$datatable-nested-row-background: $extra-light-grey-color;
$datatable-nested-row-border: $primary-blue-color;
$datatable-primary-text: $primary-text-color;
$datatable-header-text: $mid-grey-color;
$datatable-footer-text: $extra-dark-gray-color;
$datatable-pagination-grey: $default-grey-color;
$datatable-pagination-hover: $hover-gray-color;
$datatable-pagination-selected-color: $primary-blue-color;
$datatable-pagination-active-text: $white-color;
$datatable-pagination-disabled: $off-white-color;

// Scrollbar
$scrollbar-bg-color: $white-color;
$scrollbar-color: $light-blue-color;

// Variable overrides
$card-border-radius: 0.75rem;
$table-border-color: $faint-grey;




// icons
$alert-border-radius: $px8;
$alert-icon-size: $px24;
$alert-icon-margin-right: $px16;
$alert-icon-error-background-color: $error-color;

// fonts
$label-font-style: normal;
$label-font-family: 'DM Sans', sans-serif;
$label-font-weight: 500;
$label-font-style: $label-font-style;
$label-line-height: normal;
$label-margin-bottom: $px24;

$label-hint-font-family: 'DM Sans', sans-serif;
$label-hint-font-weight: 500;
$label-hint-font-style: $label-font-style;
$label-hint-font-size: $px10;

$label-xs-font-family: $label-font-style;
$label-xs-font-weight: $label-font-weight;
$label-xs-font-style: $label-font-style;

$label-xs-font-size: $px10;
$label-sm-font-size: $px12;
$label-md-font-size: $px14;
$label-lg-font-size: $px16;
