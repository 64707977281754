@use 'variables';
.spacer {
  position: relative;
  content: "";
  border-top: 1px solid variables.$faint-grey;
  width: 100%;
  margin: variables.$px24 0;
  display: block;
}
.spacer-after:after {
  @extend .spacer
}
.spacer-before:before {
  @extend .spacer
}