@use "variables";
@import "font";


:root {
  --reactive-form-primary-color: #{variables.$primary-color};
  --reactive-form-black: #{variables.$black-color};
  --reactive-form-white: #{variables.$white-color};
  --reactive-form-primary-text-color: #{variables.$black-color};
  --reactive-form-required-error-color: #{variables.$black-color};
  --reactive-form-required-star-color: #{variables.$black-color};
  --reactive-form-default-gray-color: #{variables.$default-grey-color};
  --reactive-form-extra-light-gray-color: #{variables.$extra-light-grey-color};
  --reactive-form-hover-color: #{variables.$default-grey-color};
  --reactive-form-focus-fill-color: #{variables.$dropdown-fill-color};
  --reactive-form-error-font-family: 'Poppins', sans-serif;
  --reactive-form-font-family: 'DM Sans', sans-serif;
  --reactive-form-font-regular-weight: 400;
  --reactive-form-font-medium-weight: 500;
  --reactive-form-font-semi-bold-weight: 600;
  --reactive-form-font-focus-weight: 400; //
}

.custom-select,
.form-control {
  &:disabled {
    opacity: 0.5;
  }

  &:disabled:hover {
    cursor: not-allowed;
  }

  &.ng-touched.ng-invalid {
    border: 1px solid variables.$textfield-error-red-color;
  }
}

.custom-textfield-label {
  display: block;
  text-align: left;
  color: variables.$primary-text-color;
  margin: 0 0 0.125rem;
  @extend .label-sm;
}

.reactive-form-error-block {
  display: block;
  text-align: left;
  font-weight: 500;
  color: variables.$primary-text-color;
  margin: 0 0 0.125rem;
  height: unset !important;
  @extend .label-hint;
}

.input-group-text {
  background-color: variables.$light-grey-color;
  color: variables.$black-color;
}

.custom-textfield-label-subtitle {
  display: block;
  text-align: left;
  font-weight: 500;
  color: variables.$primary-text-color;
  margin: 0 0 0.125rem;
  @extend .label-hint;
}

.std-label {
  @extend .label-sm;
}

.std-label-subtitle {
  @extend .label-hint;
}

.std-form {
  .header {

  }
  .body {
    padding: 0 variables.$px24;
    gap: variables.$px24
  }
  .footer {

  }
  .error-color {
    color: variables.$textfield-error-red-color;
  }
}
.std-row {
  display: flex;
  gap: variables.$px24;
  align-items: end;
}
.form-group {
  margin-bottom: 1.5rem;
}
.login-tab-container {
  .std-form {
    margin-top: variables.$px24;
  }
}

.std-select {
  option[placeholder] {
    pointer-events: none;
    display: none;
  }
}

