// Required for loading in body div
.main {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.zero-letter-spacing {
  letter-spacing: 0;
}

.text-truncate-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: unset;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}